.login-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: var(--colorPrincipal);
    min-height: 100vh;
}

.login-box {
    background: white;
    width: 450px;
    padding: 50px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.login-box input {
    height: 30px;
}

.login-box .ant-form-item {
    margin-bottom: 10px;
}

.login-box-bottom {
    text-align: center;
    color: rgba(0, 0, 0, 0.45);
    font-size: 13px;
}

.login-logo {
    height: 150px;
    display: block;
    margin: auto;
    margin-bottom: 30px;
}