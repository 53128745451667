@font-face {
  font-family: 'Lato';
  src: local('Montserrat'), url(./fonts/Montserrat-Medium.otf) format('opentype');
}

body{
  font-family: Montserrat;
}

code {
  color: var(--colorPrincipal);
}

.all-width{
  width: 100% !important;
}

.icon-barr{
  color: var(--colorPrincipal) !important;
}

.main-container{
  padding-top: 2rem;
  padding-right: 5rem;
  padding-bottom: 5rem;
  padding-left: 5rem;
}

.detail-container{
  padding: 1rem;
  box-shadow: rgb(0 0 0 / 7%) 0px 1px 1px, 
      rgb(0 0 0 / 7%) 0px 2px 2px, 
      rgb(0 0 0 / 7%) 0px 4px 4px, 
      rgb(0 0 0 / 7%) 0px 8px 8px, 
      rgb(0 0 0 / 7%) 0px 16px 16px;
  border-radius: 20px;
  overflow: auto
}

.img-layout{
  width: 120px;
  position: fixed;
  bottom: 5px;
  right: 0px;
}

.container-powered{
  text-align: center;
  position: fixed;
  bottom: 0px;
  /* left: 43%; */
  margin-bottom: 0px;
  color: var(--colorPrincipal);
}

.img-powered{
  width: 90px;
  position: fixed;
}

.img-side-bar{
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: -1;
}

.title{
  color: #202022;
}

.hr-title{
  border-top: 1px solid var(--blanco);
  margin-bottom: 4rem;
  box-shadow: rgb(0 0 0 / 9%) 0px 2px 1px, 
      rgb(0 0 0 / 9%) 0px 4px 2px, 
      rgb(0 0 0 / 9%) 0px 8px 4px, 
      rgb(0 0 0 / 9%) 0px 16px 8px, 
      rgb(0 0 0 / 9%) 0px 32px 16px;
}

.main-container{
  padding-top: 2rem;
  padding-right: 5rem;
  padding-bottom: 5rem;
  padding-left: 5rem;
}

.detail-container{
  padding: 1rem 30px;
  box-shadow: rgb(0 0 0 / 7%) 0px 1px 1px, 
      rgb(0 0 0 / 7%) 0px 2px 2px, 
      rgb(0 0 0 / 7%) 0px 4px 4px, 
      rgb(0 0 0 / 7%) 0px 8px 8px, 
      rgb(0 0 0 / 7%) 0px 16px 16px;
  border-radius: 20px;
  overflow: auto
}

.img-layout{
  width: 120px;
  position: fixed;
  bottom: 5px;
  right: 0px;
}

.container-powered{
  text-align: center;
  position: fixed;
  bottom: 0px;
  /* left: 43%; */
  margin-bottom: 0px;
  color: var(--colorPrincipal);
}

.img-powered{
  width: 90px;
  position: fixed;
}

.img-side-bar{
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: -1;
}

.title{
  color: #202022;
}

.hr-title{
  border-top: 1px solid var(--blanco);
  margin-bottom: 4rem;
  box-shadow: rgb(0 0 0 / 9%) 0px 2px 1px, 
      rgb(0 0 0 / 9%) 0px 4px 2px, 
      rgb(0 0 0 / 9%) 0px 8px 4px, 
      rgb(0 0 0 / 9%) 0px 16px 8px, 
      rgb(0 0 0 / 9%) 0px 32px 16px;
}

button[class~="ant-btn-icon-only"] > svg{
  margin-top: 5px;
}


.btn-submit{
  background-color: var(--colorPrincipal) !important;
  border-color:  var(--colorPrincipal) !important;
  color: var(--blanco) !important;
  border-radius: 10px;
}

.btn-submit:hover{
  background-color: var(--colorSecundario) !important;
  border-color: var(--colorPrincipal) !important;
  color: var(--blanco) !important;
}

.div-right{
  float: right;
}

.content-image-viewer{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.image-viewer{
  margin: 5px;
  cursor: pointer;
  overflow: hidden;
}

.btn-delete-img{
  float: right;
  z-index: 1;
  margin-top: 2px;
  margin-right: 2px;
}
.scrollNav::-webkit-scrollbar{
  display: none;
}

.content-footer{
  background-color: #fafafa;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    box-shadow: rgb(0 0 0 / 20%) 0px 25px 20px 20px;
}

.hero-text{
  position: relative;
  padding-left: 75px;
  padding-top: 7em;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  line-height: 3em;
}

.hero-text h1 {
  color: #202022;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 20px;
}

.hero-text h4 {
  color: var(--colorPrincipal);
}

.img-notfound {
  width: 100%;
}

::-webkit-scrollbar {
  width: 5px !important;
}

::-webkit-scrollbar:horizontal {
  height: 3px !important;
}

::-webkit-scrollbar-track {
  background-color: var(--blanco) !important;
}

::-webkit-scrollbar-thumb {
  background-color: var(--colorPrincipal) !important;
}

@media only screen and (max-width: 700px) {
  .main-container{
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

@media only screen and (max-width: 500px) {
  .main-container{
    padding-right: 1rem;
    padding-left: 1rem;
  }
}