.home-shadow {
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.home-row {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10%;
}

.home-container {
    text-align: center;
    padding: 10%;
}

.home-logo {
    height: 100px;
    display: block;
    margin: auto;
    margin-bottom: 15px;
}